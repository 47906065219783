import "@shopify/polaris/build/esm/styles.css";
import React, { Suspense, useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import CustomSkeletonPage from "./components/Skeleton/skeleton-page";
import { useDocument } from "./hook/useDocument";

const PageNotFoundPage = React.lazy(() => import("./views/PageNotFound"));

const HomePage = React.lazy(() => import("./views/home"));
const PrivacyPolicyPage = React.lazy(
  () => import("./views/clause/privacy-policy")
);
const QuestionPage = React.lazy(() => import("./views/clause/questions"));
const RegulationOfUsePage = React.lazy(
  () => import("./views/clause/regulation-of-use")
);
const ShareApplicationPage = React.lazy(
  () => import("./views/clause/share-application")
);
const TermOfServicePage = React.lazy(
  () => import("./views/clause/terms-of-service")
);

const HelpGuidePage = React.lazy(() => import("./views/clause/guide"));
function App() {
  useDocument("Ephr");
  return (
    <AppProvider i18n={enTranslations}>
      <Router>
        <div>
          {/* <HeaderBar /> */}
          <Suspense fallback={<CustomSkeletonPage />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/quy-dinh-su-dung"
                element={<RegulationOfUsePage />}
              />
              <Route
                path="/chinh-sach-bao-mat"
                element={<PrivacyPolicyPage />}
              />
              <Route
                path="/dieu-khoan-dich-vu"
                element={<TermOfServicePage />}
              />
              <Route
                path="/chia-se-ung-dung"
                element={<ShareApplicationPage />}
              />
              <Route
                path="/mot-so-cau-hoi-thuong-gap"
                element={<QuestionPage />}
              />
              <Route path="/huong-dan-su-dung" element={<HelpGuidePage />} />
              <Route path="*" element={<PageNotFoundPage />} />
            </Routes>
          </Suspense>
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;
